import React from "react";
import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as css from "./partnership.module.scss";

import ChatIcon from "../images/icon-chat2.svg";

import { Page } from "../layout/page";
import { HeaderMergedContent } from "../layout/header_merged_content";
import { Parallax } from "../layout/parallax";

export default function IndexPage(props: PageProps) {
  return (
    <Page menuId="partnership" title="Partnership">
      <HeaderMergedContent>
        <div className={css.head}>
          <h2>Partnership</h2>
          <div className={css.intro}>
            We are actively seeking partners to develop and commercialize lipid-based- topical and
            transdermal products.
          </div>
        </div>
      </HeaderMergedContent>
      <div className={css.content}>
        <div className={css.types}>
          <Parallax mz={0.05} z={0}>
            Pharmaceuticals
          </Parallax>
          <Parallax mz={0.25} z={0.75}>
            Natural Health Products
          </Parallax>
          <Parallax mz={0.1} z={0}>
            Cosmeceuticals
          </Parallax>
          <Parallax mz={0.2} z={0.5}>
            Cannabis
          </Parallax>
        </div>

        <div className={css.talk}>
          <h3>
            <ChatIcon className={css.icon} /> Let's talk
          </h3>
          <a href="mailto:partnership@cnano.ca">partnership@cnano.ca</a>
        </div>
      </div>
    </Page>
  );
}
