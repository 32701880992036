// extracted by mini-css-extract-plugin
export var content = "partnership-module--content--u3QOn";
export var fadeInBottom = "partnership-module--fade-in-bottom--G7ENU";
export var fadeInLeft = "partnership-module--fade-in-left--sKInL";
export var fadeInRight = "partnership-module--fade-in-right--J8aWG";
export var fadeInTop = "partnership-module--fade-in-top--CZFU3";
export var head = "partnership-module--head--wLylu";
export var icon = "partnership-module--icon--nvRzE";
export var intro = "partnership-module--intro--BNwuu";
export var talk = "partnership-module--talk--8td7M";
export var types = "partnership-module--types--MznwJ";